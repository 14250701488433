<template>
  <v-dialog v-model="dialog" width="550" persistent overlay-opacity="0.75">
    <v-card rounded="lg">
      <v-card-title v-show="step === 1" class="text-18">
        <span style="word-break: normal; overflow-wrap: break-word">
          Verificação de Segurança
        </span>
      </v-card-title>

      <v-card-text>
        <v-stepper v-model="step" elevation="0" class="transparent">
          <v-stepper-content step="1" class="pa-0">
            <p>
              Para finalizar o pagamento, precisamos confirmar algumas
              informações.
            </p>

            <v-form @submit.prevent v-model="valid">
              <h6 class="my-4">Confirme o Endereço de Faturamento</h6>

              <div v-if="!address" class="text-center">
                <PlaceSearch
                  placeholder="Busque o endereço de faturamento"
                  label="Endereço de faturamento"
                  :value="address"
                  @input:raw="formatAddress"
                  outlined
                  :delay="1000"
                  hint="Endereço cadastrado no cartão"
                  :hide-details="false"
                  :search-types="['address']"
                />

                <v-btn text x-small @click="address = {}">
                  Preencher endereço manualmente
                </v-btn>
              </div>
              <template v-else>
                <v-text-field
                  v-model="address.street"
                  label="Endereço"
                  hint="Endereço com número"
                  :disabled="loading"
                  outlined
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  dense
                />
                <v-text-field
                  v-model="address.street2"
                  label="Complemento"
                  :disabled="loading"
                  hint="Apartamento, bloco, etc"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="address.city"
                  label="Cidade"
                  :disabled="loading"
                  outlined
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  dense
                />
                <div class="d-flex gap-4">
                  <v-text-field
                    v-model="address.state"
                    class="flex-1"
                    label="Estado"
                    hint="Sigla do estado (SP, RJ, PR, etc)"
                    :disabled="loading"
                    outlined
                    :rules="[
                      (v) => !!v || 'Campo obrigatório',
                      (v) =>
                        (v && v.length === 2) ||
                        'Sigla do estado com 2 caracteres',
                    ]"
                    dense
                  />
                  <v-text-field
                    v-model="address.country"
                    label="País"
                    class="flex-1"
                    hint="Sigla do país (BR, US, etc)"
                    :disabled="loading"
                    outlined
                    :rules="[
                      (v) => !!v || 'Campo obrigatório',
                      (v) =>
                        (v && v.length === 2) ||
                        'Sigla do país com 2 caracteres',
                    ]"
                    dense
                  />
                </div>
                <v-text-field
                  v-model="address.zipCode"
                  label="CEP"
                  :disabled="loading"
                  outlined
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  dense
                  type="tel"
                />
              </template>
            </v-form>

            <v-alert v-if="error" type="error">
              {{ error }}
            </v-alert>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div>
              <v-scroll-y-transition leave-absolute hide-on-leave>
                <div
                  :key="verificationStep"
                  class="d-flex flex-column gap-2 text-center justify-center px-2 pt-4"
                  style="height: 270px"
                >
                  <v-icon size="60" left>
                    {{ verificationSteps[verificationStep].icon }}
                  </v-icon>
                  <h5 class="mb-0">
                    {{ verificationSteps[verificationStep].title }}
                  </h5>
                  <p
                    v-if="verificationSteps[verificationStep].description"
                    style="text-wrap: balance"
                  >
                    {{ verificationSteps[verificationStep].description }}
                  </p>
                </div>
              </v-scroll-y-transition>
            </div>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>

      <v-card-actions v-if="step === 1">
        <v-btn text @click="close" :disabled="loading"> Cancelar </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          @click="verify"
          :disabled="!valid || !address || loading"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import TICKET from "@/services/app/ticket";
import PlaceSearch from "@/views/global/PlaceSearch.vue";
import generateDeviceFingerprint from "@/utils/deviceFingerprint";
import { mapGetters } from "vuex";
import getIp from "@/utils/getIp";

import { captureException } from "@sentry/vue";

const { VUE_APP_VERCEL_ENV } = process.env;

export default {
  components: {
    PlaceSearch,
  },
  metaData: {
    script:
      "https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js",
  },
  data: () => ({
    dialog: false,
    step: 1,
    loading: false,
    error: null,
    valid: false,
    address: null,
    verificationStep: "IDLE",
    verificationSteps: {
      IDLE: {
        icon: "mdi-shield-check",
        title: "Verificação Necessária",
        description:
          "Para garantir a segurança do seu pagamento, precisamos que você realize algumas verificações de segurança.",
      },
      DEVICE_VERIFICATION: {
        icon: "mdi-cellphone-link",
        title: "Verificando seu Dispositivo",
        description:
          "Coletando informações do dispositivo para garantir a autenticidade do pagamento.",
      },
      PAYMENT_DATA: {
        icon: "mdi-text-box",
        title: "Coletando Dados do Pagamento",
        description:
          "Reunindo os detalhes do seu pedido e informações de pagamento.",
      },
      BANK_CONNECTION: {
        icon: "mdi-lock",
        title: "Estabelecendo Conexão Segura",
        description:
          "Criando uma conexão criptografada com sua instituição financeira para proteger seus dados.",
      },
      BANK_VERIFICATION: {
        icon: "mdi-bank",
        title: "Confirmando Dados Bancários",
        description:
          "Estamos verificando os dados do seu pedido com sua instituição financeira.",
      },
      PAYMENT_PROCESS: {
        icon: "mdi-credit-card",
        title: "Processando pagamento",
        description: "Finalizando o pagamento, aguarde um instante.",
      },
    },
    invisibleForm: false,
    cardErrosDictionary: {
      INVALID_NUMBER: "Número do cartão inválido",
      INVALID_SECURITY_CODE: "Código de segurança inválido",
      INVALID_EXPIRATION_MONTH: "Mês de vencimento inválido",
      INVALID_EXPIRATION_YEAR: "Ano de vencimento inválido",
      INVALID_PUBLIC_KEY: "Chave pública inválida",
      INVALID_HOLDER: "Nome do titular inválido",
    },
    countryISOCodes: {
      BR: "BRA",
      US: "USA",
      MX: "MEX",
      AR: "ARG",
      CL: "CHL",
      CO: "COL",
      PE: "PER",
    },
  }),
  methods: {
    open() {
      this.dialog = true;
      this.address = null;
      this.verificationStep = "IDLE";
      this.step = 1;
      this.loading = false;
      this.error = null;
    },
    formatAddress(data) {
      const street = [
        this.findAddressComponent(data.address_components, "route")?.long_name,
      ];
      const number = this.findAddressComponent(
        data.address_components,
        "street_number"
      )?.long_name;
      if (number) street.push(number);

      this.address = {
        name: data.name,
        street: street.join(", "),
        number: this.findAddressComponent(
          data.address_components,
          "street_number"
        )?.long_name,
        street2: this.findAddressComponent(
          data.address_components,
          "subpremise"
        )?.long_name,
        neighborhood: this.findAddressComponent(
          data.address_components,
          "sublocality"
        )?.long_name,
        city: this.findAddressComponent(
          data.address_components,
          "administrative_area_level_2"
        )?.long_name,
        state: this.findAddressComponent(
          data.address_components,
          "administrative_area_level_1"
        )?.short_name,
        country: this.findAddressComponent(data.address_components, "country")
          ?.short_name,
        zipCode: this.findAddressComponent(
          data.address_components,
          "postal_code"
        )?.long_name,
        lat: data.geometry.location.lat(),
        lng: data.geometry.location.lng(),
      };
    },
    findAddressComponent(data, componentName) {
      return data.find((item) => item.types.includes(componentName));
    },
    normalizeText(text) {
      if (!text) return "";
      return text
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9 ]/g, "");
    },
    async pay(encrypted, threeDSData) {
      try {
        if (this.verificationStep === "PAYMENT_PROCESS") return;
        this.error = null;
        this.loading = true;
        this.verificationStep = "PAYMENT_PROCESS";
        const expiration = moment(this.card.expiration, "MM/YY");

        const { success, data } = await TICKET.pay(this.paymentId, {
          type: "CREDIT_CARD",
          card: {
            encrypted,
            installments: this.card.installments,
            bin: this.card.number.substring(0, 6),
            last4: this.card.number.substring(this.card.number.length - 4),
          },
          authentication: threeDSData,
        });

        if (!success) throw new Error(data.message);

        this.close();
        this.$emit("success", data.status);
      } catch (error) {
        this.$emit("update");
        this.error =
          error.message || "Erro ao processar pagamento, tente novamente";
        this.loading = false;
        this.step = 1;
      }
    },
    async verify() {
      try {
        this.error = null;
        this.loading = true;
        this.step = 2;

        // Get Device Fingerprint
        this.verificationStep = "DEVICE_VERIFICATION";
        // const deviceFingerprint = await generateDeviceFingerprint();
        const deviceFingerprint = "unknown";
        const ipData = await getIp();
        await this.sleep(1000);

        // Get Payment Data
        this.verificationStep = "PAYMENT_DATA";
        const expiration = moment(this.card.expiration, "MM/YY");
        const card = PagSeguro.encryptCard({
          publicKey: this.integrationData.publicKey,
          holder: this.card.holder.name,
          number: this.card.number,
          expMonth: expiration.format("MM"),
          expYear: expiration.format("YYYY"),
          securityCode: this.card.security_code,
        });
        if (card.hasErrors) {
          const errors = card.errors;
          if (errors.length > 0)
            throw {
              message:
                this.cardErrosDictionary[errors[0].code] ||
                "Erro ao processar pagamento, tente novamente",
            };
        }
        const encrypted = card.encryptedCard;

        const paymentData = await this.getPaymentData(encrypted);

        // Get Bank Connection
        const token = await this.getToken();
        this.verificationStep = "BANK_CONNECTION";
        await this.sleep(500);

        // Get Bank Verification
        this.verificationStep = "BANK_VERIFICATION";

        PagSeguro.setUp({
          session: token,
          env: this.environment,
        });

        const result = await PagSeguro.authenticate3DS({
          data: paymentData,
        }).catch((err) => {
          if (err instanceof PagSeguro.PagSeguroError) {
            console.log(err);
            console.log(err.detail);
          }
          throw err;
        });
        this.processReturn(encrypted, result);
      } catch (error) {
        captureException(error, {
          tags: { module: "3ds-pagseguro" },
          extra: {
            paymentId: this.paymentId,
            cardBin: this.card?.number?.replace(/\D/g, "").substring(0, 6),
          },
        });
        console.log(error);
        this.error = "Erro ao validar o pagamento, tente novamente";
        this.loading = false;
        this.step = 1;
      }
    },
    processReturn(encrypted, { status, ...data }) {
      if (status === "AUTH_FLOW_COMPLETED") {
        if (data.authenticationStatus === "AUTHENTICATED")
          this.pay(encrypted, data);
        else {
          this.error =
            "Erro ao confirmar o cartão, por favor verifique os dados e tente novamente";
          this.loading = false;
          this.step = 1;
        }
      } else if (status === "AUTH_NOT_SUPPORTED") {
        this.error =
          "O seu cartão não é aceito para o pagamento, tente novamente com outro cartão";
        this.loading = false;
        this.step = 1;
      } else if (status === "CHANGE_PAYMENT_METHOD") {
        this.error =
          "O cartão selecionado não é aceito para o pagamento, tente novamente com outro cartão";
        this.loading = false;
        this.step = 1;
      } else {
        this.error =
          "Erro ao confirmar o cartão, por favor verifique os dados e tente novamente";
        this.loading = false;
        this.step = 1;
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async getPaymentData(encrypted) {
      const amount = this.installments.find(
        (i) => i.value === this.card.installments
      ).price;
      const phone = this.user.phone.replace(/\D/g, "");

      return {
        dataOnly: false,
        amount: {
          value: parseInt(amount * 100),
          currency: "BRL",
        },
        customer: {
          name: this.normalizeText(this.user.name),
          email: this.user.email,
          phones: [
            {
              country: this.user.ddi,
              area: parseInt(phone.substring(0, 2)),
              number: parseInt(phone.substring(2)),
              type: "MOBILE",
            },
          ],
        },
        paymentMethod: {
          type: "CREDIT_CARD",
          installments: this.card.installments,
          card: {
            encrypted,
            holder: { name: this.normalizeText(this.user.name) },
          },
        },
        billingAddress: {
          street: this.address.street,
          number: this.address.number,
          complement: this.address.street2,
          district: this.address.neighborhood,
          city: this.address.city,
          regionCode: this.address.state,
          country: this.countryISOCodes[this.address.country] || "BRA",
          postalCode: parseInt(this.address.zipCode.replace(/\D/g, "")),
        },
      };
    },
    async getToken() {
      const response = await TICKET.payVerify(this.paymentId);
      return response.data.session;
    },
    close() {
      this.dialog = false;
      this.$emit("close");
    },
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    installments() {
      return this.payment.fees.map((fee) => {
        return {
          text: `${fee.installment}x`,
          value: fee.installment,
          interestFree: fee.interestFree,
          interestValue: fee.value,
          price: fee.total,
        };
      });
    },
    environment() {
      return "PROD";

      const env = VUE_APP_VERCEL_ENV || "development";
      if (env !== "production") return "SANDBOX";
      return "PROD";
    },
  },

  watch: {
    "card.expiration": {
      handler: function (value) {
        if (!value) return;

        if (value.length === 1 && value > 1)
          this.card.expiration = `0${value}/`;

        if (value.length === 2 && value > 12) this.card.expiration = "12/";
      },
      immediate: true,
    },
    "address.state": {
      handler: function (value) {
        if (!value) return;
        this.address.state = value.toUpperCase();
      },
      immediate: true,
    },
    "address.country": {
      handler: function (value) {
        if (!value) return;
        this.address.country = value.toUpperCase();
      },
      immediate: true,
    },
  },

  mounted() {
    this.$root.$on("3ds-verification", this.open);
  },
  beforeDestroy() {
    this.$root.$off("3ds-verification", this.open);
  },

  props: {
    card: {
      type: Object,
      default: () => ({}),
    },
    paymentId: {
      type: String,
      required: true,
    },
    payment: {
      type: Object,
      required: true,
    },
    party: {
      type: Object,
      required: true,
    },
    tickets: {
      type: Array,
      default: () => [],
    },
    integrationData: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
#Cardinal-Modal {
  border-radius: 0.5rem;
  max-width: calc(100vw - 2rem);
}

#Cardinal-CCA-IFrame {
  max-width: calc(100vw - 2rem - 40px) !important;

  @media (max-width: 425px) {
    max-width: calc(100vw - 5rem) !important;
  }
}

#Cardinal-ModalContent {
  height: min(calc(100vh - 4rem), 550px) !important;
  max-height: 100%;
  iframe {
    height: 100%;
  }
}
</style>
